import React from "react";
import { Layout } from "../../../../src/components";
import CheckoutForm from "../../components/checkout/checkout-form";

const Checkout = () => (
  <Layout>
    <div className="layout">
      <h1>Checkout</h1>
      <CheckoutForm />
    </div>
  </Layout>
);

export default Checkout;
