import React from "react";
import CheckoutCartItem from "../checkout-cart-item";

const Index = ({ cart }) => {
  return (
    <>
      {cart ? (
        <>
          {/*Product Listing*/}
          <table className="table table-hover">
            <thead>
              {/* eslint-disable */}
						<tr className="woo-next-cart-head-container">
							<th className="woo-next-cart-heading-el" colSpan="2">Product</th>
							<th className="woo-next-cart-heading-el">Total</th>
						</tr>
						</thead>
						<tbody>
						{ cart.products.length && (
							cart.products.map( (item, index) => (
								<CheckoutCartItem key={ index } item={ item } />
							) )
						) }
						{/*Total*/}
						<tr className="">
							<td className="woo-next-checkout-total" colSpan="2">Subtotal</td>
							<td className="woo-next-checkout-total">{ cart.totalProductsPrice }</td>
						</tr>
						<tr className="">
							<td className="woo-next-checkout-total" colSpan="2">Total</td>
							<td className="woo-next-checkout-total">{ cart.totalProductsPrice }</td>
						</tr>
						</tbody>
					</table>
				</>
			) : '' }
		</>
	)
};

export default Index;
