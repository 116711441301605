import React, { useState, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Billing from "../billing";
import YourOrder from "../your-order";
import PaymentModes from "../payment-mode";
import { AppContext } from "../../context/AppContext";
import validateAndSanitizeCheckoutForm from "../../../validator/checkout";
import { getFormattedCart, createCheckoutData } from "../../../utils/functions";
import OrderSuccess from "../order-success";
import GET_CART from "../../../queries/get-cart";
import CHECKOUT_MUTATION from "../../../mutations/checkout";
import CheckoutError from "../checkout-error";
import "./style.scss";

const CheckoutForm = () => {
  const { auth } = useContext(AppContext);
  const initialState = {
    firstName: auth?.user?.firstName ? auth?.user?.firstName : "",
    lastName: auth?.user?.lastName ? auth?.user?.lastName : "",
    company: auth?.billing?.company ? auth?.billing?.company : "",
    country: auth?.billing?.country ? auth?.billing?.country : "",
    address1: auth?.billing?.address1 ? auth?.billing?.address1 : "",
    address2: auth?.billing?.address2 ? auth?.billing?.address2 : "",
    city: auth?.billing?.city ? auth?.billing?.city : "",
    state: auth?.billing?.state ? auth?.billing?.state : "",
    postcode: auth?.billing?.postcode ? auth?.billing?.postcode : "",
    phone: auth?.billing?.phone ? auth?.billing?.phone : "",
    email: auth?.user?.email ? auth?.user?.email : "",
    createAccount: false,
    username: "",
    password: "",
    customerNote: "",
    paymentMethod: "gpls-rfq",
    errors: null,
  };

  // Use this for testing purposes, so you dont have to fill the checkout form over an over again.
  // const initialState = {
  //   firstName: "Mark",
  //   lastName: "Mollart",
  //   address1: "73 Troy Terrace",
  //   address2: "Unit 3",
  //   city: "Perth",
  //   state: "Western Australia",
  //   country: "AU",
  //   postcode: "6014",
  //   phone: "0893880800",
  //   email: "mark@distl.com.au",
  //   company: "Distl",
  //   createAccount: false,
  //   username: '',
  //   password: '',
  //   customerNote: "My Order notes",
  //   paymentMethod: "gpls-rfq",
  //   errors: null,
  // };

  const { cart, setCart } = useContext(AppContext);
  const [input, setInput] = useState(initialState);
  const [orderData, setOrderData] = useState(null);
  const [requestError, setRequestError] = useState(null);

  // useEffect(() => {
  //   // const updatedCart = getFormattedCart(data);
  //   // localStorage.setItem("woo-cart", JSON.stringify(updatedCart));
  //   setCart(cart);
  // }, []);

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART' );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-cart", JSON.stringify(updatedCart));

      // Update cart data in React Context.
      if (updatedCart) {
        setCart(updatedCart);
      }
    },
  });

  // Checkout or CreateOrder Mutation.
  const [checkout, { data: checkoutResponse, loading: checkoutLoading }] = useMutation(CHECKOUT_MUTATION, {
    variables: {
      input: orderData,
    },
    onCompleted: () => {
      // console.warn( 'completed CHECKOUT_MUTATION' );
      refetch();
    },
    onError: (error) => {
      if (error) {
        console.log("error005:", error);
        setRequestError(error.graphQLErrors[0].message);
      }
    },
  });

  /*
   * Handle form submit.
   *
   * @param {Object} event Event Object.
   *
   * @return {void}
   */
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const result = validateAndSanitizeCheckoutForm(input);
    if (!result.isValid) {
      setInput({ ...input, errors: result.errors });
      return;
    }
    const checkOutData = createCheckoutData(input);
    setOrderData(checkOutData);
    setRequestError(null);
  };

  /*
   * Handle onchange input.
   *
   * @param {Object} event Event Object.
   *
   * @return {void}
   */
  const handleOnChange = (event) => {
    if ("createAccount" === event.target.name) {
      const newState = { ...input, [event.target.name]: !input.createAccount };
      setInput(newState);
    } else {
      const newState = { ...input, [event.target.name]: event.target.value };
      setInput(newState);
    }
  };

  useEffect(() => {
    if (null !== orderData) {
      // Call the checkout mutation when the value for orderData changes/updates.
      /* eslint-disable */
      checkout();
    }
  }, [orderData]);

  return (
    <>
      {cart ? (
        <form onSubmit={handleFormSubmit} className="checkout-form">
          <div className="row">
            {/*Billing Details*/}
            <div className="col">
              <h2 className="mb-4">Billing Details</h2>
              <Billing input={input} handleOnChange={handleOnChange} />
            </div>
            {/* Order & Payments*/}
            <div className="col">
              {/*	Order*/}
              <h2 className="mb-4">Your Order</h2>
              <YourOrder cart={cart} />

              {/*Payment*/}
              <PaymentModes input={input} handleOnChange={handleOnChange} />
              <p>
                <button
                  className="action"
                  type="submit"
                >
                  Place Order
                </button>
              </p>

              {/* Checkout Loading*/}
              {checkoutLoading && <p>Processing Order...</p>}
              {requestError && <CheckoutError requestError={ requestError }/> }
            </div>
          </div>
        </form>
      ) : (
        ""
      )}

      {/*Show message if Order Success*/}
      <OrderSuccess response={checkoutResponse} />
    </>
  );
};

export default CheckoutForm;
