import React from "react";
import Error from "../error";

const Index = ({ input, handleOnChange }) => {
  return (
    <div className="mt-3">
      <Error errors={input.errors} fieldName={"paymentMethod"} />
      {/*Direct bank transfers*/}
      <div className="form-check woo-next-payment-input-container mt-2">
        <label className="form-check-label">
          <input
            onChange={handleOnChange}
            checked={input.paymentMethod === "gpls-rfq" ? true : false}
            value="gpls-rfq"
            className="form-check-input"
            name="paymentMethod"
            type="radio"
          />
          <span className="woo-next-payment-content">Request For Quote</span>
        </label>
      </div>
      {/*Direct bank transfers*/}
      <div className="form-check woo-next-payment-input-container mt-2">
        <label className="form-check-label">
          <input
            onChange={handleOnChange}
            checked={input.paymentMethod === "bacs" ? true : false}
            value="bacs"
            className="form-check-input"
            name="paymentMethod"
            type="radio"
          />
          <span className="woo-next-payment-content">Direct Bank Transfer</span>
        </label>
      </div>
    </div>
  );
};

export default Index;
